$(function(){
    // Swup
    //mport Swup from 'swup';

    // TARGET CACHING ELEMENTS VARIABLES
    var el       = $(window),
    init         = true,
    logo         = $('#home .main-header'),
    header       = $('.main-header'),
    didScroll    = false,
    prevscroll   = 0,
    buttons    = $('.buttons'),
    sidebarNav   = $('.sidebar-nav'),
    hamburgerBtn = $('.menu-btn');


    // ANIMATE ON SCROLL PLUGIN
    AOS.init({
      disable: 'mobile'
    });


    // MAIN NAVIGATION
    hamburgerBtn.click(function(event){
        event.preventDefault();
        $(this).toggleClass('open');
        sidebarNav.width(el.width());
        sidebarNav.toggleClass('open');
        header.toggleClass('open');
    });


    var hoverOut = function(){
        var obj = $(this);
        obj.find('.excerpt:visible').slideUp(100);
        obj.find('a.card').removeClass('active');
    };


    // BASIC PAGE SCROLL
    $(".scroll").click(function(event){
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top - 250
            }, 1000);
          }
    });

    // SCROLLING + FIXING HEADER
    el.scroll(function() {
        didScroll = true;
    });

     setInterval(function() {
        if ( didScroll ) {
            didScroll = false;

            var newscroll = el.scrollTop();

            if( newscroll >= 100 ){

                if( !buttons.hasClass('show') ){
                    buttons.addClass('show');
                }
            }

            else {
                if( buttons.hasClass('show') ){
                    buttons.removeClass('show');
                }
            }

        }

        prevscroll = newscroll;
        init=false

    }, 250);


});

